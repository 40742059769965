@import "variables";

////////////////////////
/***** home-pests *****/

.services-corp {
	padding: 2em .5em;
	margin: 2em auto;
	width: 100%;

	@media only screen and (min-width: 1300px) {
		margin: 0 auto;
		padding: 4em 0.5em;
	}

	.main {
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		position: relative;

		header {
			padding-bottom: 1em;
			text-align: center;
			font-size: 20px;

			h4 {
				font-size: 1.5em;
				margin: 0 auto;
			}
		}
	}

	.flex {
		-ms-flex-pack: justify;
		justify-content: space-between;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
	}

	.services-list {
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		position: relative;
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		max-width: 95%;
		margin: 1em auto;

		@media (min-width: 550px) and (max-width: 767px) {
			max-width: 500px;
		}

		@media (min-width: 1251px) {
			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 3px;
				background-color: #E5E5E5;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}

		}

		.svgicon-inner,
		icon {
			display: flex;
			font-size: 2.5em;
			-webkit-transform: translateY(0);
			-ms-transform: translateY(0);
			transform: translateY(0);
			transition: transform .5s ease 0s, color .5s ease 0s;

			svg,
			svg[data-use] {
				height: 1em;
				width: 1em;
				fill: currentColor;
			}

			>svg {
				pointer-events: none;
			}

			// +strong {
				//		  margin-top: 1.111em;
			// }
		}

		strong {
			font-weight: 500;
			text-align: center;
			color: #393939;
			line-height: 1.429em;
			-webkit-transform: translateY(0);
			-ms-transform: translateY(0);
			transform: translateY(0);
			transition: transform .5s ease 0s;
			-webkit-flex: 1 1 auto;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			justify-content: space-around;
			display: flex;
			flex-direction: column;
			padding: 0 .5em;
			text-align: center;
			margin-top: .5em;

			@media only screen and (min-width: 550px) {
				text-align: left;
			}
		}

		li {
			display: block;
			width: 100%;
			transition: transform .65s cubic-bezier(0.175, 1.1, 0.320, 1.1) 0s;
			-webkit-transform-origin: bottom;
			-ms-transform-origin: bottom;
			transform-origin: bottom;
			-webkit-transform: scale(0, 0);
			-ms-transform: scale(0, 0);
			transform: scale(0, 0);
			font-size: 16px;
			width: 50%;

			&:nth-child(2) {
				transition-delay: .1s;
			}

			&:nth-child(3) {
				transition-delay: .2s;
			}

			&:nth-child(4) {
				transition-delay: .3s;
			}

			&:nth-child(5) {
				transition-delay: .4s;
			}

			&:nth-child(6) {
				transition-delay: .5s;
			}

			&:nth-child(7) {
				transition-delay: .6s;
			}

			&:nth-child(8) {
				transition-delay: .7s;
			}

			&:nth-child(n + 5) {
				-webkit-transform-origin: top;
				-ms-transform-origin: top;
				transform-origin: top;

				.svgicon {
					padding-bottom: 0;
					padding-top: 1.5em;

					&:before {
						bottom: auto;
						top: 0;
						-webkit-transform-origin: top;
						-ms-transform-origin: top;
						transform-origin: top;
					}
				}
			}

			.svgicon {
				text-align: left;
				-webkit-align-items: row;
				-ms-flex-align: row;
				align-items: row;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				padding-bottom: 2.5em;
				width: 100%;
				position: relative;
				cursor: pointer;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				text-decoration: none;
				color: $color-main;
				transition: color .4s ease 0s;
				outline: none;
				flex-direction: column;
				align-items: center;

				@media (min-width: 1251px) {
					&:before {
						content: '';
						position: absolute;
						bottom: 0;
						height: 2.333em;
						width: 3px;
						background-color: #E5E5E5;
						left: 50%;
						transition: height .5s ease 0s;
						-webkit-transform-origin: bottom;
						-ms-transform-origin: bottom;
						transform-origin: bottom;
						-webkit-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
					}
				}
			}

		}

		@media only screen and (min-width: 550px) {
			li {


				a {
					flex-direction: row;
				}
			}
		}

		@media only screen and (min-width: 800px) {
			li {
				width: 25%;
				-webkit-flex: 1 1 auto;
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;

				.svgicon {
					flex-direction: column;
					-webkit-align-items: column;
					-ms-flex-align: column;
					align-items: column;
					-webkit-flex-direction: column;
					-ms-flex-direction: column;
					flex-direction: column;
					padding-bottom: 3em;
					text-align: center;

					strong {
						text-align: center;
						display: block;
					}
				}

				&:nth-child(n + 5) {

					a {
						padding-bottom: 0;
						padding-top: 3em;
					}
				}
			}

			.svgicon-inner,
			icon {
				display: block;
				font-size: 3.5em;
			}
		}
	}

}

.no-touch .services-corp .services-list {
	li span:hover {
		text-decoration: none;
		color: $color-main;

		.svgicon-inner,
		icon {
			-webkit-transform: translateY(-.3em);
			-ms-transform: translateY(-.3em);
			transform: translateY(-.3em);
		}

		strong {
			-webkit-transform: translateY(-.9em);
			-ms-transform: translateY(-.9em);
			transform: translateY(-.9em);
		}

		&:before {
			height: 3.5em;
		}

	}

	li:nth-child(n + 5) a:hover {
		.svgicon-inner,
		icon {
			-webkit-transform: translateY(.3em);
			-ms-transform: translateY(.3em);
			transform: translateY(.3em);
		}

		strong {
			-webkit-transform: translateY(.9em);
			-ms-transform: translateY(.9em);
			transform: translateY(.9em);
		}
	}
}

.services-corp {

	&.showing,
	&.already-visible {
		.services-list {
			li {
				-webkit-transform: scale(1, 1);
				-ms-transform: scale(1, 1);
				transform: scale(1, 1);
			}
		}
	}
}


////////////////////////////////////////
/***** home reviews / why-choose  *****/

#whychooseus {
	// margin-top: 40px;
}

.homeReviews {
	display: block;
    margin: 0 auto;
    position: relative;
    top: -80px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
	border-radius: 2px;
	width: 90%;
	padding: 20px;
	@media (min-width: 768px) {
		padding: 40px;
		width: 80%;
		font-size: 1.2em;
	}
	h3 {
		text-align: center;
	}

	.homeReviewsTitle {
		margin: auto;
		border-top-right-radius: 6px;
		border-top-left-radius: 6px;
		padding: 8px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 0;
		width: 100%;
		max-width: 766px;
		margin-top: 100px;
	}

	.reviewBody {
		display: block;
		margin: 10px 40px 20px;
		position: relative;
		font-weight: 400;
	}

	@media only screen and (max-width: 450px) {
		.reviewBody {
			clear: both;
			width: 100%;
			width: calc(100% - 30px);
			margin: 10px 15px;
		}
	}

	.reviewDate {
		position: relative;
		margin: 0 auto;
		display: block;
		line-height: 1;
		padding: 0 10px;
		font-size: 1.5em;
		font-weight: 100;
	}

	.startQuote,
	.endQuote {
		opacity: 0.4;
		position: absolute;
		font-size: 2em;
	}

	.startQuote {
		i {
			transform: scale(-1, 1);
		}

		top: -40px;
		left:-45px;
	}

	.endQuote {
		right: -45px;
		text-align: right;
		bottom: -40px;
	}

	@media (max-width: 450px) {
		.startQuote,
		.endQuote {
			font-size: 1.5em;
		}
		.startQuote {
			top: -30px;
			left: -30px;
		}
		.endQuote {
			bottom: -30px;
			right: -30px;
		}
	}

	.reviewName {
		display: block;
		margin: 0 auto;
		padding: 0 10px;
		font-size: 1.1em;
		font-weight: 300;
	}

	.reviewStars {
		display: inline-block;
		color: lighten($color-highlight, 5%);
		text-align: right;
		display: block;
		margin: 1em auto;
		display: block;
		transition: .2s;
		text-align: center;

		.fa-star {
			font-size: 1.3em;
			margin: 2px;
			text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
		}

		@media only screen and (max-width:480px) {
			margin: 0px;
			padding-left: .5em;
			padding-right: 2em;
			padding-bottom: 1.5em;
		}

	}

	.reviewBody {
		padding: 0 .5em;
		font-size: 1.3em;
	}

	.slick-list, .slick-track {
		height: 100%;
	}
	.single-review.slick-slide {
		vertical-align: middle;
		float: none;
		padding: 0 20px;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}
}

.home-why-choose-inner {
	display: flex;

	.home-why-choose-left {
		flex: 1;
		width: 32%;
	}
	.home-why-choose-right {
		flex: 2;
		width: 65%;
	}	

	@media only screen and (max-width:1250px) {
		display: block;
		.home-why-choose-left,
		.home-why-choose-right {
			width: 100%;
		}

		.twoColumnContainer {
			display: block;
		}
	}
}



section.home-awards {
	text-align: center;
	margin: 60px auto;
	h3 {
		font-size: 2em;
		margin-bottom: 40px;
	}
	.home-awards-inner {
		@media (min-width: 500px) {
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
		}
		@media (max-width: 499px) {
			img {
				max-width: 50vw;
			}
		}
	}
}

.home-awards-inner img {
	max-width: 24vw;
	padding: 20px;
}
.home-content {
	font-size: 1.2em;
	padding: 20px 40px;
}

.barba-container > .home {
	background: #fff;
	.home-inner {
		background: white;
		position: relative;
		z-index: 1;
	}
}

.home-intro {
	display: flex;
	align-items: stretch;
	justify-content: center;
	padding: 80px 5% 160px;
	> * {
		flex: 1;
	}
	.home-intro-buttons {
		display: flex;
		flex-wrap: wrap;
		font-size: 1.5em;
		@media (min-width: 768px) {
			font-size: 2em;
		}
		.home-intro-btn {
			.fas, svg {
				color: $color-highlight;
				fill: $color-highlight;
			}
			flex: 1 0 50%;
			max-width: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 15px;
			text-align: center;
			
			transition: 0.2s transform ease;
			&:hover, &:visited, &:focus {
				transform: scale(1.05);
			}
		}
	}
	@media (max-width: 999px) {
		flex-wrap: wrap;
		> * {
			flex: 1 0 98%;
			max-width: 100%;
		}
		.home-intro-buttons {
			order: -1;
			.home-intro-btn {
				
			}
		}
	}
}

.page-link {
	color: $color-secondary;
	@media (min-width: 768px) {
		.is-scrolled & {
			color: #fff;
		}
	}
}


.home-form-outer {
  padding: 80px 20px;
  background: $color-main;
  background: url('../../img/water-wave-with-splashes-2560.jpg');
  @media (max-width: 1920px) {
	background-image: url('../../img/water-wave-with-splashes-1920.jpg');
  }
  @media (max-width: 1440px) {
	background-image: url('../../img/water-wave-with-splashes-1440.jpg');
  }
  @media (max-width: 768px) {
	background-image: url('../../img/water-wave-with-splashes-768.jpg');
  }
  background-size: cover;
  background-position: center;
  box-shadow: 0 100px 50px -50px #fdfdfd inset, 0 -100px 50px -50px #fdfdfd inset;

}

.home-recent-posts-outer {
}
